
.input-label {
    font-style: normal;
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-xs);
    letter-spacing: 0.3px;
    color: var(--primary);
}
.input-field {
    background: var(--white);
    border: 1px solid #A9A9A9;
    box-sizing: border-box;
    border-radius: 4px;
    width: 100%;
    max-width: 139px;
    padding: 2px 6px;
    height: 100%;
    max-height: 43px;
}

.range-span-text {
    font-style: normal;
    font-weight: var(--font-weight-300);
    font-size: var(--font-size-xs);
    line-height: 35px;
    color: #A9A9A9;
}
.slider-position {
    position: relative;
}
.slider-css{
    background: #757575;
    border-radius: 10px;
    height: 5px;
    width: 1px;
    position: absolute;
    left: 0;
    top: 11px;
    z-index: 2;
}
.bg-black-section {
    background: var(--primary);
}
.sip-calc h5 {
    font-style: normal;
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-md);
    line-height: 35px;
    letter-spacing: 0.3px;
    color: var(--white);
    margin-bottom: 0;
}

.sip-calc h1 {
    font-style: normal;
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-xl);
    line-height: 56px;
    letter-spacing: 0.2px;
    color: var(--white);
    margin-bottom: 0;
}

.view-disclaimer-text {
    font-size: var(--font-size-xxs);
    font-weight: var(--font-weight-normal);
    line-height: 24px;
    text-align: center;
    color: #212529;
    text-decoration: underline;
}


/*.btn_ad {*/
/*    background: var(--white);*/
/*    border-radius: 6px;*/
/*    font-style: normal;*/
/*    font-weight: var(--font-weight-bold);*/
/*    font-size: var(--font-size-sm);*/
/*    line-height: 18px;*/
/*    text-transform: uppercase;*/
/*    text-decoration: none;*/
/*    color: var(--active);*/
/*    padding: 15px 45px;*/
/*}*/

.and-symbol {
    height: 23px;
    width: 23px;
    font-size: var(--font-size-sm);

}

@media only screen and (max-width: 992px) {
    .input-field {
        max-width: none;
    }

    .input-label {
        font-size: var(--font-size-xxs);
    }

    .sip-calc h5 {
        font-size: var(--font-size-md);
    }

    .sip-calc h1 {
        font-size: var(--font-size-lg);
    }
}
