.footer-container {
    background-color: var(--active);
    color: var(--white);
    position: relative;
    padding: 96px 0 76px;
    bottom: 0;
}

.footer-x-padding{
    padding-left: 110px;
    padding-right: 110px;
}

.footercard .fot_bold {
    font-weight: var(--font-weight-bolder);
    font-size: var(--font-size-sm);
    margin-bottom: 38px;
    color:var(--white)
}

.footer-text {
    text-decoration: none;
    font-weight: var(--font-weight-normal);
    line-height: 1.5;
    font-size: var(--font-size-sm);
    color:var(--white)
}
.footer-text:hover{
    color:var(--white);
}

p.foot_txt {
    display: flex;
    align-items: baseline;
}
a {
    text-decoration: none;
    color: var(--white);
}

p.sip {
    background: var(--primary);
    border-radius: 6px;
    color: var(--white);
    font-style: normal;
    font-weight: var(--font-weight-bold);
    font-size: 13px !important;
    line-height: 18px;
    text-align: center;
    width: 100%;
    max-width: 122px;
    padding: 10px 10px;
}

.cop_text p {
    font-style: normal;
    font-weight: normal;
    font-size: var(--font-size-xs);
    line-height: 20px;
    color: var(--white);
    margin-bottom: 0;
    padding-right: 0;
}

.socal_icon {
    padding-top: 66px;
    padding-bottom: 18px;
}

@media only screen and (max-width: 1200px) {
    .footer-x-padding{
        padding-left: 50px;
        padding-right: 50px;
    }
}

@media only screen and (max-width: 992px) {
    .footer-x-padding {
        padding-left: 16px;
        padding-right: 16px;
    }
    .footer-text {
        text-decoration: none;
        font-weight: var(--font-weight-normal);
        line-height: 1.5;
        font-size: var(--font-size-xs);
        color:var(--white)
    }

    .footer-container {
        padding-top: 0;
        padding-bottom: 1px;
    }

    .socal_icon {
        padding-top: 13px;
    }

    .footercard .fot_bold {
        margin-top: 50px;
        margin-bottom: 25px;
    }

    .footercard p {
        margin-bottom: 10px;
    }
}

@media only screen and (max-width: 767px) {
    p.fot_text {
        padding-right: 0;
    }

    .footercard {
        margin-top: 14px;
    }

    .footercard p {
        display: flex;
        align-items: baseline;
    }

    .footercard p i {
        padding-right: 14px;
    }

    p.fot_bold {
        font-weight: bold;
        font-size: var(--font-size-md);
    }

}

