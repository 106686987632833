.cursor {
    cursor: pointer;
}

a {
    text-decoration: none;
    color: inherit;
}

* ::-webkit-scrollbar {
    width: 7px;
    height: 4px;
}

* ::-webkit-scrollbar-track {
    background: var(--gray-light) !important;
}

* ::-webkit-scrollbar-thumb {
    background: var(--gray-dark) !important;
    border-radius: 10px !important;
}

* ::-webkit-scrollbar-thumb:hover {
    background: var(--gray-dark) !important;
}

.webkit-inline {
    display: -webkit-inline-flex !important;
}


.font-bolder{
    font-weight: var(--font-weight-bolder) !important;
}
.font-normal{
    font-weight: var(--font-weight-normal) !important;

}

.page-title{
    font-size: var(--font-size-xxl);
    font-weight: var(--font-weight-bolder);
    line-height: 1.1;
    color:var(--primary);
    letter-spacing: var(--line-spacing-0);
}

.page-sub-title {
    font-style: normal;
    font-size: var(--font-size-xl);
    font-weight: var(--font-weight-bolder);
    line-height: 1.1;
    letter-spacing: .3px;
}
.section-header{
    font-style: normal;
    font-weight: var(--font-weight-bolder);
    font-size: var(--font-size-sm);
    color:var(--primary);
    line-height: 26px;
}


.section-title{
    font-size: var(--font-size-xxxl);
    font-weight: var(--font-weight-bold);
    line-height: var(--line-height);
    letter-spacing: var(--line-spacing-0);
    color:var(--primary);
    font-style:normal;
}

.section-description-text{
    font-weight: var(--font-weight-normal);
    font-size: var(--font-size-lg);
    line-height: 35px;
    color: var(--secondary);
    font-style: normal;
}

.section-padding-bottom{
    padding-bottom: 140px;
}

.section-padding-top{
    padding-top: 140px;
}

.content{
    font-style: normal;
    font-size: var(--font-size-md);
    font-weight: var(--font-weight-300);
    color: var(--primary);
    letter-spacing: var(--character-spacing-0);
}

.contact-us-text{
    font-style: normal;
    font-weight: var(--font-weight-normal);
    font-size: var(--font-size-sm);
    line-height: 26px;
    letter-spacing: var(--character-spacing-0);
    color: var(--primary);
}

.contact-us{
   text-decoration: underline;
    padding-left: 4px;
    color: var(--primary);

}

.contact-us:hover{
    color: var(--primary) !important;

}

input[type=text], select, textarea {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: 47px;
    resize: vertical;
    font-size: var(--font-size-sm);
}
input[type=number], select, textarea {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    resize: vertical;
}
input[type=submit]:hover {
    background-color: #45a049;
}

.inputtype select {
    margin: 14px 0;
    border: none;
    padding: 12px 12px 12px 25px;
    border-radius: 0;
    background-repeat: no-repeat;
    background-position: right center
}
.form-control:focus {
    box-shadow: none;
}

input.ng-invalid.ng-touched {
    border: var(--danger) 1px solid;
}
textarea.ng-invalid.ng-touched {
    border: var(--danger) 1px solid;
}
input.submitted.ng-invalid {
    border: 1px solid var(--danger);
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

input[type=range] {
    position: relative;
    -webkit-appearance: none;
    width: 100%;
    height: 5px;
    background: #ddd;
    outline: none;
    -webkit-transition: .2s;
    transition: opacity .2s;
}

input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 15px;
    height: 15px;
    background: var(--white);
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    cursor: pointer;
}

input[type="range"]::-moz-range-thumb {
    width: 15px;
    height: 15px;
    background: var(--white);
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    cursor: pointer;
}

input.form-control {
    border: none;
    border-radius: 4px;
}

.form-check-input:checked {
    background: var(--primary);
    border-color: var(--primary);
}

.silder_div[_ngcontent-tsf-c52] input[type=range][_ngcontent-tsf-c52] {
    background: rgba(171, 168, 168, .35000000000000003);
    z-index: 9;
}

.radio_btn {
    display: flex;
}

.radio_btn .form-check {
    width: 100%;
    max-width: 250px;
}

.radio_btn label.form-check-label {
    font-style: normal;
    font-weight: var(--font-weight-300);
    font-size: var(--font-size-sm);
    letter-spacing: 0.3px;
    color: var(--primary);
    margin-left: 13px;
}
.primary-button{
    font-style: normal;
    font-weight: var(--font-weight-300);
    font-size: var(--font-size-sm);
    line-height: 26px;
    border: 1px solid var(--primary);
    border-radius: 6px;
    color: var(--primary);
    background-color: var(--white);
    padding: 6px 12px;
    width: 138px;
}
.secondary-button {
    box-sizing: border-box;
    border-radius: 6px;
    border-color: var(--white) !important;
    color: var(--white);
    font-style: normal;
    font-weight: var(--font-weight-bolder);
    font-size: var(--font-size-xs);
    line-height: 1.2;
    padding: 13px 26px;
    text-transform: uppercase;
}

@media only screen and (max-width: 992px) {
    .page-title{
        font-size: var(--font-size-xl);
        line-height: 38.5px;
        text-align: left;

    }

    .page-sub-title{
        font-size: var(--font-size-lg);
        line-height: 28px;
        letter-spacing: 0.3px;
        text-align: left;

    }

    .section-title{
        font-size: var(--font-size-xl);
    }

    .section-description-text {
        font-size: var(--font-size-sm);
        line-height: 28px;
        letter-spacing: 0.2px;
    }
    .section-padding-bottom {
        padding-bottom: 70px;
    }
    .section-padding-top{
        padding-top: 70px;
    }

    input[type=text], select, textarea {
        margin-top: 20px;
    }

}
